<template>
    <div class="xw_page_container">
        <xwHead />
        <div class="member_content">
            <div>
                    <div class="cart">
                        <div class="c-title-wapper">
                            <div class="c-title-l">
                                <div class="c-title">{{ $t("待領獎勵") }}</div>
                                <div class="c-title-num">{{ data.currency }} {{ numFormat(data.total_amount || 0) }}
                                </div>
                            </div>
                            <div class="btn-wapper" v-if="actionDay.index > 7">
                                <el-button :loading="btnLoding" class="button button_blue btn_more" @click="showPop">{{
                                    $t("更多獎勵") }}</el-button>
                                <div class="c-title-r" v-if="data.surplus_sign_count > 0">{{ $t("補卡次數") }}{{
                                    data.surplus_sign_count }}</div>
                            </div>

                        </div>
                        <div class="list-wapper">
                            <div v-loading="item.loading" @click="actionDay.index == index ? singIn(item) : null"
                                :class="['list-item', item.status == 1 ? 'list-item-ac' : '', actionDay.index == index ? 'list-item-ac2' : '']"
                                v-for="item, index in data.day_list" :key="index">
                                <div class="item-icon-wapper">
                                    <img src="@/assets/images/singin-icon1.png" class="item-icon"
                                        v-if="item.status == 0 && item.reward_amount > 0">
                                    <img src="@/assets/images/integral.png" class="item-icon item-icon2"
                                        v-else-if="item.status == 0">
                                    <img src="@/assets/images/singin-icon2.png" class="item-icon" v-else>
                                </div>
                                <span class="item-num">{{ item.amount > 0 ? data.currency : "X" }} {{ item.amount > 0 ?
                                    numFormat(item.amount) : item.integral }}</span>
                                <div class="item-bottom">
                                    <span v-if="item.status != 0" style="color: #306AEF;">{{ $t("已簽到") }}</span>
                                    <span v-else-if="actionDay.index == index" style="color: #306AEF;">{{
                                        $t(actionDay.text) }}</span>
                                    <span v-else>{{ item.day.toString().padStart(2, "0") }}</span>
                                </div>
                                <div class="bage"></div>
                            </div>
                        </div>
                    </div>
                    <div class="html" v-html="data.introduce" style="margin-bottom: 10px;"></div>
                    <singinPop ref="singinPop"></singinPop>
                </div>
        </div>
        <xwTabbar v-if="$store.state.windowWidth <= 768" />

    </div>
</template>

<script>
import xwTabbar from "@/components/xwTabbar.vue";
import { sign_getSign_api, sign_userSign_api, sign_addSign_api } from "@/api/singin.js"
import singinPop from '../singin/singinPop.vue'
import xwHead from "@/components/xwHead.vue";
export default {
    components: {
        xwTabbar,
        xwHead,
        singinPop
    },
    data() {
        return {
            loading: false,
            btnLoding: false,
            data: {
                day_list: []
            }
        }
    },
    computed: {
        // 判断签到或补签所在的天数
        actionDay() {
            const i = this.data.day_list.findIndex(item => item.status == 0)
            if ((i + 1) > this.data.current_day) return {}
            return {
                text: this.data.current_day == (i + 1) ? "簽到" : "補籤",
                index: i
            }
        }
    },
    async mounted() {
        this.loading = true
        await this.getData()
        this.loading = false

    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        async showPop() {
            this.btnLoding = true
            await this.$refs.singinPop.show()
            this.btnLoding = false
        },
        // 获取数据
        async getData() {
            const { data } = await sign_getSign_api()
            if (data.code == 1) {
                this.data = data.data
            }
        },
        // 签到或者补签
        async singIn(item) {
            item.loading = true
            this.$forceUpdate()
            const api = this.actionDay.text == '簽到' ? sign_userSign_api : sign_addSign_api
            const { data } = await api({
                sign_id: this.data.id,
                day: item.day
            })
            if (data.code == 1) {
                this.getData()
                this.$message({
                    message: data.msg,
                    type: 'success'
                });
            } else {
                this.$message.error(data.msg);
            }
            item.loading = false
            this.$forceUpdate()
        }

    },
}
</script>

<style lang="less" scoped>
.xw_page_container {
    width: 100vw;
    min-height: var(--vh);
    background-color: #ebebeb;
}

.member_header {
    position: relative;
    width: 100%;
    height: 60px;
    background-color: #3a9b7c;

    @media (max-width:768px) {
        position: fixed;
        top: 0;
        left: 0;
        height: 40px;
        background-color: #3f9eff;
    }
}

.member_title_name {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    margin-left: 34px;
}

.search_item {
    margin-left: 0;
    height: 0;
    position: absolute;
    top: 24px;
    left: 179px;
    width: max-content;
}

.member_tab_list {
    list-style: none;
    display: flex;
    align-items: center;
    user-select: none;
}

.member_tab_item {
    min-width: auto;
    padding: 0 17px;
    text-align: center;
    height: 36px;
    line-height: 36px;
    width: max-content;
    font-size: 13px;
    font-weight: normal;
    color: #fff;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
}

.member_tab_item.active {
    background-color: #ebebeb !important;
    color: #3a9b7c;
}

.bg_header {
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 99;
}

.header_content {
    position: relative;
    color: #fff;
    width: 100%;
    height: 100%;
    line-height: 40px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    font-weight: bold;
}

.btn_back {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
    width: 40px;
    display: block;
    background: url('../../assets/images/xw/btn_back.svg') no-repeat center;
    background-size: auto 40%;
}

.member_content {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 15px;

    @media (max-width:768px) {
        padding: 0;
        width: 100%;
    }
}

.main_content {
    width: 90%;
    max-width: 1000px;
    min-height: 300px;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 3px;
    font-size: .8em;
    line-height: 1.7em;
    word-break: break-all;
    text-align: justify;
    color: #000;

    @media (max-width:768px) {
        width: 100%;
    }
}
</style>
<style scoped src="@/assets/css/singin.less" lang="less"></style>