import { render, staticRenderFns } from "./singin.vue?vue&type=template&id=1f7b5564&scoped=true&"
import script from "./singin.vue?vue&type=script&lang=js&"
export * from "./singin.vue?vue&type=script&lang=js&"
import style0 from "./singin.vue?vue&type=style&index=0&id=1f7b5564&prod&lang=less&scoped=true&"
import style1 from "@/assets/css/singin.less?vue&type=style&index=1&id=1f7b5564&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f7b5564",
  null
  
)

export default component.exports